import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import styled from 'styled-components';
import SubmitButton from 'components/global/Buttons/SubmitButton';
import TextInput from 'components/global/textInput/TextInput';
import ErrorPopup from 'components/global/ErrorPopup';
import DropdownFormik from 'components/global/dropdown/DropdownFormik';
import { formatTakerResponse } from 'containers/pendingPage/helpers/formatTakerResponse';
import { takerFieldTypes } from 'containers/app/helpers/takerFieldTypes';
import { takerValidation } from 'services/validations/takerValidation';
import { useAssessmentContext } from 'services/context/assessmentContext/useAssessmentContext';
import { useGetAssessmentInfo } from 'containers/landingPage/hooks/useGetAssessmentInfo';
import { useHandleRequest } from 'containers/inProgressPage/hooks/useHandleRequest';
import { useThemeContext } from 'services/context/themeContext/useThemeContext';
import { FlexContainer } from 'components/local/styles/StyledComponents';
import { NavWrapper } from 'components/local/navbar/styles/StyledComponents';
import NetworkNotification from 'containers/inProgressPage/subcomponents/NetworkNotification';
import { attemptLimitUomTypes } from 'containers/app/helpers/attemptStateTypes';

// Quick implementation for #4889, we should consider making the label configurable
const PERSONAL_INFORMATION_ASSESSMENTS = [
  'modee-data-assessment-instructors',
  'modee-data-assessment-students',
  'modee-data-analytics-public-sector-assessment'
];

const CandidateDetails = () => {
  const { taker_fields, slug } = useGetAssessmentInfo();
  const {
    assessmentAttempt: {
      id: attemptId,
      taker,
      taker_field_responses: takerFieldResponses
    }
  } = useAssessmentContext();
  const { themeState } = useThemeContext();
  const { handleValidateAssessmentStart } = useHandleRequest();
  const [fieldObj, setFieldObj] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [wasMaxAttemptCompleted, setWasMaxAttemptCompleted] = useState(false);
  const [attemptLimit, setAttemptLimit] = useState(-1);
  const [attemptLimitUom, setAttemptLimitUom] = useState(null);
  const [attemptLimitRemaining, setAttemptLimitRemaining] = useState(-1);
  let initialValues = {};

  useEffect(() => {
    const fieldInfo = {};

    taker_fields.forEach(({ content, id, field_type }) => {
      const name = content[0] && content[0].title;

      return (fieldInfo[name] = {
        taker_field_id: id,
        assessment_attempt_id: attemptId,
        field_type: `${field_type}_response`
      });
    });

    setFieldObj(fieldInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taker_fields]);

  const getInitialValues = () => {
    initialValues = { email: taker ? taker.email : '' };

    taker_fields
      .map(field => {
        const resultObj = takerFieldResponses.find(
          resp => resp.taker_field_id === field.id
        );
        return {
          ...field,
          result: resultObj ? resultObj.result : ''
        };
      })
      .forEach(field => (initialValues[field.content[0].title] = field.result));

    return initialValues;
  };

  const formikProps = useFormik({
    initialValues: getInitialValues(),
    validationSchema: takerValidation(taker_fields),
    onSubmit: async values => {
      const body = formatTakerResponse(values, fieldObj);
      setIsLoading(true);
      const {
        wasMaxAttemptCompleted,
        attemptLimit,
        attemptLimitUom,
        attemptLimitRemaining
      } = await handleValidateAssessmentStart(body);
      if (wasMaxAttemptCompleted) {
        setWasMaxAttemptCompleted(true);
        setAttemptLimit(attemptLimit);
        setAttemptLimitUom(attemptLimitUom);
        setAttemptLimitRemaining(attemptLimitRemaining);
        setIsLoading(false);
      }
    }
  });

  const renderFields = formikProps => {
    return taker_fields.map(field => {
      const { content, field_type, is_required, id } = field;
      const validContent = !!content[0];
      const title = validContent ? content[0].title : 'no title provided';
      const fieldResponse = initialValues[title];
      const placeholder = validContent ? content[0].placeholder : '';

      switch (field_type) {
        case takerFieldTypes.text:
          return (
            <TextInput
              key={id}
              name={title}
              label={title.split('_').join(' ')}
              formikProps={formikProps}
              optional={!is_required}
              capitalize={true}
              placeholder={placeholder}
              disabled={!!fieldResponse}
            />
          );
        case takerFieldTypes.select:
          const { options } = field;

          return (
            <DropdownFormik
              key={id}
              name={title}
              label={title.split('_').join(' ')}
              disabled={false}
              options={options.map(option => {
                return {
                  id: option.id,
                  text: option.content[0].text_display
                };
              })}
              optional={!is_required}
              placeholder={placeholder}
              formikProps={formikProps}
              showInitialErrors={true}
            />
          );
        default:
          return null;
      }
    });
  };

  const getCandidateDetailHeader = () => {
    let content = 'Candidate Details';
    if (PERSONAL_INFORMATION_ASSESSMENTS.includes(slug)) {
      content = 'Personal Information';
    }

    return <Header>{content}</Header>;
  };

  const renderErrorPopup = () => {
    const title =
      attemptLimitUom === attemptLimitUomTypes.MAX
        ? `This assessment cannot be taken more than ${attemptLimit} time(s)`
        : `This assessment cannot be retaken within ${attemptLimit} month(s)`;
    const message =
      attemptLimitUom === attemptLimitUomTypes.MAX
        ? "You've reached the max number of attempts with this email."
        : `You've already taken the assessment within the set timeframe. Eligible date: ${attemptLimitRemaining}`;
    return (
      wasMaxAttemptCompleted && (
        <ErrorPopup
          title={title}
          message={message}
          onClose={() => setWasMaxAttemptCompleted(false)}
        />
      )
    );
  };

  return (
    <section id="candidate-details-page">
      {getCandidateDetailHeader()}
      <form onSubmit={formikProps.handleSubmit}>
        <Wrapper justifyContent="space-between" flexWrap="wrap">
          <TextInput
            name="email"
            label="Email"
            placeholder=""
            formikProps={formikProps}
            disabled={!!taker}
          />
          {renderFields(formikProps)}
        </Wrapper>
        <NavWrapper background={themeState.color}>
          <FlexContainer
            width="100%"
            justifyContent="flex-end"
            maxWidth="1024px"
          >
            <SubmitButton
              text="START ASSESSMENT"
              formikProps={formikProps}
              isLoading={isLoading}
              className="inverted"
            />
          </FlexContainer>
        </NavWrapper>
      </form>
      <FlexContainer direction="column" alignItems="center">
        <NetworkNotification />
      </FlexContainer>
      {renderErrorPopup()}
    </section>
  );
};

export const Wrapper = styled(FlexContainer)`
  @media (max-width: 667px) {
    flex-direction: column;
  }
  & > div {
    flex-basis: 47%;
  }
`;

export const Header = styled.div`
  font-family: ${props => props.theme.boldFontFamily};
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15;
  margin: 16px 0;
`;

export default CandidateDetails;
