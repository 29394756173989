export const attemptStateTypes = {
  INITIALIZED: 'INITIALIZED',
  PENDING: 'PENDING',
  PAUSED: 'PAUSED',
  IN_PROGRESS: 'IN_PROGRESS',
  SUBMITTED: 'SUBMITTED',
  COMPLETE: 'COMPLETE'
};

export const attemptLimitUomTypes = {
  MAX: 'max',
  INTERVAL: 'interval'
};
